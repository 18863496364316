import { Injectable } from '@angular/core';
import { Apollo, QueryRef } from 'apollo-angular';
import { ServerStatus, ServerStatusDocument } from '../../graphql/generated/gen-types';

@Injectable({
	providedIn: 'root',
})
export class ServerService {
	constructor(private apollo: Apollo) {}

	public serverStatus(): QueryRef<any> {
		return this.apollo.watchQuery({ query: ServerStatusDocument });
	}
}
