<div style="background-image: url('./assets/images/bg2020.jpg'); background-size: cover; height: 100% !important">
	<div class="theme-container">
		<div style="display:flex; flex-direction: column; gap: 40px">
			<mat-card>
				<mat-card-header>
					<div mat-card-avatar style="background-image: url('./assets/images/balloonspinner.gif'); background-size: cover"></div>
					<mat-card-title>Quick Release App</mat-card-title>
					<mat-card-subtitle>Under Maintenance</mat-card-subtitle>
				</mat-card-header>
				<mat-card-content>
					<div style="display:flex; flex-direction: column; gap: 5px; margin-top: 40px">
						<span>Start of maintenance: {{ serverStatus?.start | formatDate: 'DD-MM-YYYY HH:mm' }}</span>
						<span>Expected Duration: {{ serverStatus?.expectedDuration }} hours</span>
						<span>Purpose: {{ serverStatus?.purpose }}</span>
					<br/>
						<span>Please be patient, we are working hard to finish the maintenance</span>
					</div>
				</mat-card-content>
			</mat-card>
		</div>
	</div>
</div>
