import { Component, OnInit } from '@angular/core';
import { ServerStatus } from 'src/app/core/graphql/generated/gen-types';
import { ServerService } from 'src/app/core/services/admin/server.service';

@Component({
	selector: 'app-maintenance',
	templateUrl: './maintenance.component.html',
	styleUrls: ['./maintenance.component.scss'],
})
export class MaintenanceComponent implements OnInit {
	serverStatus: ServerStatus;

	constructor(private serverService: ServerService) {}

	ngOnInit(): void {
		this.serverService.serverStatus().valueChanges.subscribe((result) => {
			this.serverStatus = result.data.serverStatus;
		});
	}
}
